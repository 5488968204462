<template>
  <div class="spotHallPageBox">
    <!-- 开市时间 -->
    <div class="openingTime">
      <img class="annunciateImg" src="@/assets/icon/annunciate.png" alt="icon">
      <span>温馨提示：{{ `开市时间为${$store.state.tradeTimeRule.tradeDay === '00' ? '工作日&nbsp;&nbsp;' : '每天&nbsp;&nbsp;' }`+ `${$store.state.tradeTimeRule.state === 1 ? $store.state.tradeTimeRule.openTime + '-' + $store.state.tradeTimeRule.closeTime : '00:00-23:59'}` }}</span>
    </div>
    <!-- 选择搜索框 -->
    <Search @changeGoodsCondition="changeGoodsCondition" />
    <!-- 排序 -->
    <div class="sortPageBox">
      <span :class="sortActive === 1 ? 'active' : ''" @click="changeActive(1)">综合排序</span>
      <span :class="sortActive === 2 ? 'active' : ''" @click="changeActive(2)">最新发布</span>
      <span :class="sortActive === 3 ? 'active' : ''" @click="changeActive(3)"> 即将截止</span>
      <span class="address">收货地区</span>
      <el-cascader
        v-model="receivingArea"
        :props="{ label: 'name', value: 'name', children: 'children' }"
        :options="allCityOption"
        clearable
        @change="getReceivingArea"
      />
      <span class="want-to-buy" @click="clickToBuy">
        发布求购
      </span>
    </div>
    <!-- 产品列表 -->
    <div class="productListBox">
      <div v-for="(item, index) in listData" :key="item.id" class="content-box">
        <div class="item-top">
          <div class="top-left">
            {{ (item.cmpShortName || '').slice(0, 4) || item.cmpName.slice(0, 4) }}
          </div>
          <div class="top-center">
            <p class="title">
              {{ item.cmpName }}
            </p>
            <div class="address-box">
              <p>
                <span class="label">收货地：</span>
                {{ item.receivingArea && item.receivingArea.length ? item.receivingArea.replace(/,/g, '/') : '' }}
              </p>
              <p>
                <span class="label">发布时间：</span>
                {{ item.createTime }}
              </p>
              <p>
                <span class="label">截止时间：</span>
                {{ item.deadLine }}
              </p>
            </div>
          </div>
          <div class="top-right">
            <div class="view-details bottom" @click="viewDetails(item.id)">
              查看详情
            </div>
            <div class="content bottom">
              <el-popover
                placement="top-start"
                title="求购联系人"
                width="200"
                trigger="hover"
                :content="item.contact + item.phone"
              >
                <i slot="reference" class="icon-btn" />
              </el-popover>
            </div>
          </div>
        </div>
        <div class="table-content">
          <div :class="item.develop ? 'table-out' : 'table-open'">
            <Table :item-data="itemData" :list-data="item && item.tradeWantToBuyGoodsInfoReqs ? item.tradeWantToBuyGoodsInfoReqs : []">
              <template #goodsOneCategoryName="{row}">
                <span>{{ row.goodsOneCategoryName }}/{{ row.goodsTwoCategoryName }}</span>
              </template>
              <template #unitPriceIncludingTax="{row}">
                <span>￥{{ row.unitPriceIncludingTax }}</span>
              </template>
            </Table>
          </div>
          <p v-if="item.tradeWantToBuyGoodsInfoReqs && item.tradeWantToBuyGoodsInfoReqs.length > 3" class="open-more" :class="{'out-more' : item.develop}" @click="openTable(item.develop, index)">
            {{ item.develop ? '展开更多' : '收起' }}<i class="el-icon-arrow-up" />
          </p>
        </div>
      </div>
    </div>
    <Pagination v-if="listData.length>0" :get-data-list="getHallGoods" :params="formInline" :total="total" />
    <NoData v-else class="noData" :type="3" />
  </div>
</template>

<script>
import Search from '@/components/Search.vue'
import Pagination from '@/components/Pagination.vue'
import NoData from '@/components/NoData.vue'
import Table from '@/components/Table.vue'
import { getSelectAreaTree } from '@/http/common'
import { goodsHallPagePurchaseHallGoods } from '@/http/wantToBuy'
export default {
  components: { NoData, Search, Pagination, Table },
  data() {
    return {
      sortActive: 1,
      // 所有地区
      allCityOption: [],
      receivingArea: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        orderRuleData: {
          orderColumnName: 'modifyTime',
          orderRule: 'desc'
        }
      },
      total: 0,
      listData: [],
      // 搜索条件列表
      searchConditionList: [],
      itemData: [
        { label: '品名', prop: 'goodsOneCategoryName', align: 'left', width: 195 },
        { label: '材质', prop: 'goodsMaterialName', align: 'left', width: 180 },
        { label: '规格', prop: 'goodsSpecName', align: 'left', width: 170 },
        { label: '钢厂/厂家', prop: 'manufactor', align: 'left', width: 180 },
        { label: '含税单价（元/吨）', prop: 'unitPriceIncludingTax', align: 'left', width: 198 },
        { label: '求购重量（吨）', prop: 'quantity', align: 'left', width: 120 }
      ],
      token: null,
      userInfo: {}
    }
  },
  created() {
    this.token = localStorage.getItem(`mallFrontDeskToken${this.$store.state.userCmpRole}`)
    this.getHallGoods()
    // 获取所有的城市
    getSelectAreaTree('00', res => {
      this.allCityOption = [...res.data]
    })
  },
  mounted() {
    if (this.token) {
      this.userInfo = JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)) || {}
    }
  },
  methods: {
    // 点击排序
    changeActive(index) {
      this.sortActive = index
      switch (index) {
        case 2:
          this.formInline.orderRuleData.orderColumnName = 'modifyTime'
          this.formInline.orderRuleData.orderRule = 'desc'
          break
        case 3:
          this.formInline.orderRuleData.orderColumnName = 'deadLine'
          this.formInline.orderRuleData.orderRule = 'asc'
          break
        default:
          this.formInline.orderRuleData = {}
          break
      }
      this.getHallGoods()
    },
    // 现货大厅列表
    getHallGoods() {
      goodsHallPagePurchaseHallGoods(this.formInline, res => {
        this.listData = res.data.records
        this.listData.map((item) => {
          item.develop = true
          return item
        })
        this.total = res.data.total
      })
    },
    // 查看详情
    viewDetails(id) {
      const routeUrl = this.$router.resolve({
        path: '/buyHall/wantBuyDetail',
        query: { id }
      })
      window.open(routeUrl.href, '_blank')
    },
    // 搜索条件查询
    changeGoodsCondition(value) {
      this.formInline.goodsTwoCategoryName = this.formInline.goodsMaterialName = this.formInline.goodsSpecName = ''
      if (!value || value.length === 0) return this.getHallGoods()
      this.formInline.goodsTwoCategoryName = value[0].goodsName
      const material = value.find((item) => item.type === 'material')
      if (material) this.formInline.goodsMaterialName = material.goodsName
      const spec = value.find((item) => item.type === 'spec')
      if (spec) this.formInline.goodsSpecName = spec.goodsName
      this.getHallGoods()
    },
    // 获取当前选择地区
    getReceivingArea(val) {
      if (!val) return
      this.formInline.receivingArea = val.toString()
      this.getHallGoods()
    },
    // 点击展开
    openTable(develop, index) {
      this.$set(this.listData[index], 'develop', !develop)
      this.$forceUpdate()
    },
    // 发布求购按钮
    clickToBuy() {
      if (!this.token || !this.token.length) {
        this.$message.warning('还未登录， 请登录')
        this.$router.push({ path: '/login' })
        return
      }
      switch (this.userInfo.cmpState) {
        case '01':
          this.infoMakeup()
          return
        case '-1':
          this.infoMakeup()
          return
        default:
        {
          if (this.$store.state.userCmpRole !== '02') return this.$message.warning('账号所属企业暂无买家权限')
          sessionStorage.activeHomePath = '/buyHall/wantBuyAdd'
          const routeData = this.$router.resolve({ path: '/buyHall/wantBuyAdd' })
          window.open(routeData.href, '_blank')
          break
        }
      }
    },
    infoMakeup() {
      this.$confirm(
        `亲爱的${this.userInfo.userName || this.userInfo.orgName}用户, 为了您能正常使用平台提供的功能，请尽快完善公司信息`,
        '信息未完善',
        {
          confirmButtonText: '立即完善',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/orgStructure/enterpriseInformation`, '_blank')
      })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss">
.spotHallPageBox {
  width: $centerPlateWidth;
  margin: 18px auto 0;
.noData{
    padding: 35px 0 100px;
  }
  .productListBox {
    background-color: #fff;
    padding: 30px 24px;
    .content-box {
      margin-bottom: 48px;
      &:nth-child(2n) .top-left {
        background-color: #EDA59F;
      }
    }
    .item-top {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      .top-left {
        width: 52px;
        height: 64px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        background-color: #FBC9A4;
        color: #FFFFFF;
        font-size: 22px;
        padding: 8px 16px;
        text-align: center;
        margin-right: 16px;
        text-align-last: justify;
        text-align: justify;
        text-justify: distribute-all-lines;
      }
      .top-center {
        .title {
          color: #333;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 6px;
        }
        .address-box {
          display: flex;
          color: #333333;
          font-size: 16px;
          p {
            margin-right: 32px;
          }
          .label {
            color: #666666;
          }
        }
      }
      .top-right {
        margin-left: auto;
        display: flex;
        .view-details {
          font-size: 16px;
          width: 103px;
          height: 38px;
          line-height: 38px;
          margin-right: 4px;
        }
        .content {
          width: 38px;
          height: 38px;
          line-height: 38px;
          .icon-btn {
            display: inline-block;
            width: 24px;
            height: 24px;
            object-fit: cover;
            vertical-align: middle;
            background: url('../../assets/img/contact.png') top center no-repeat;
            background-size: contain;
          }
          &:hover {
            .icon-btn {
              background: url('../../assets/img/hover_contact.png') top center no-repeat;
              background-size: contain;
            }
          }
        }

        .bottom {
          border: 1px solid rgba(231,231,231,1);
          background: rgba(255,255,255,1);
          color: $themeColors;
          text-align: center;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background-color: $themeColors;
            color: #fff;
          }
        }
      }
    }
    .table-content {
      .el-table__row,.el-table__header{
        td:first-of-type .cell,th:first-of-type .cell{
          padding-left: 24px;
        }
      }
      .el-table__row,.el-table__header{
        td:last-of-type .cell,th:last-of-type .cell{
          padding-right: 24px;
          text-align: left;
        }
      }
      .el-table__row td,.el-table__header th{
        padding: 10px 0;
      }
      .table-out {
        max-height: 159px;
        overflow: hidden;
      }
      .table-open {
        height: auto;
      }
      .open-more {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #E7E7E7;
        cursor: pointer;
        color: #333333;
        font-size: 14px;
        i {
          transition: 0.6s;
        }
        &.out-more {
          i {
            transform: rotateX(-180deg);

          }
        }
      }
    }

    .price {
      font-weight: bold;
      color: #FF0000;
    }
  }

  .sortPageBox {
    display: flex;
    align-items: center;
    margin: 10px 0 20px 0;
    height: 42px;
    background-color: rgba(0,0,0,0.03);
    padding-left: 18px;
    .el-cascader {
      .el-input__inner {
        height: 32px;
        width: 200px;
        border: 0;
        background-color: #fff;
      }
      span {
        margin-right: 0;
      }
    }

    span {
      width: auto;
      height: 40px;
      margin-right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: $mainFont;
      cursor: pointer;

      .icon {
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        i {
          font-size: 10px;
          color: #C1C1C1;
          transform: translateY(-2px);
        }

        i:first-of-type {
          transform: translateY(2px);
        }

        .active {
          color: #333;
        }
      }
    }
     span.active {
      border-bottom: 1px solid $themeColors;
      color: $themeColors;
    }
    .address {
      margin-right: 8px;
    }

    .want-to-buy {
      margin-left: auto;
      color: $themeColors;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .openingTime {
    margin: 18px 0;
    background: rgba(243,131,27,0.1);
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #F3831B;

    .annunciateImg {
      height: 20px;
      width: 20px;
      margin: 0 12px 0 21px;

    }
  }
}

</style>

